import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const AboutCard = ({ name, title, email, image, bio }) => {
  return (
    <section className="group relative h-full  min-h-[300px] w-full ">
      <div
        className={
          'absolute -top-20 left-[calc(50%-80px)] aspect-square overflow-hidden h-40 w-40 rounded-full border border-gray-300 bg-white duration-300 group-hover:scale-[105%] group-hover:shadow-lg'
        }
      >
        <GatsbyImage image={getImage(image)} alt={name + ' ' + title} className="w-full h-full" />
      </div>
      <div className="flex h-full flex-col rounded-lg border border-gray-300 px-5 pt-24 pb-10 text-center shadow-md duration-300 hover:shadow-lg">
        <p className="font-bold uppercase">{name}</p>
        <i>{title}</i>
        {email ? <a href={'mailto:' + email}>{email}</a> : <>&nbsp;</>}
        <p
          className="mt-4 border-t border-gray-200 text-left text-sm leading-loose"
          dangerouslySetInnerHTML={{ __html: bio }}
        />
      </div>
    </section>
  )
}

AboutCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  bio: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default AboutCard
